import './App.css';
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Footer from './components/footer';
import logo from './assets/logo.png';
import dash from './assets/dash.png';
import plus from './assets/plus.png';
import twitter from './assets/twitter.png';
import discord from './assets/discord.png';
import opensea from './assets/os.png';
import minus from './assets/minus-sign.png';
import $ from "jquery";
import { Contract, Web3Provider, Provider } from "zksync-web3";
import nft from './assets/pre-reveal.jpg';

const os = () => {
	window.open("https://opensea.io/collection/the-universe-of-smilesx");
}
//0x632b749DDBBf4dC70Bada94047132d331Bf4D501
/*
<div class="comMain">
						<div class="comSection">
							<div class="comT">JOIN OUR <br /> COMMUNITY</div>
							<button class="joinBtn">Join Now</button>
						</div>
						<img class="comImg" src={comBanner} />
					</div>
*/

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount = 1;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let public_mint_status = false;
let presale_mint_status = false;
let presale_status = false;
let contractZksync;
let song;
let song1;
let wlEligible;
let wlMinted;
let presaleMinted;
let presaleCost;
let wlCost;
let total_wl_count;
let total_presale_count;
let presale_per_wallet;

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses = [
	"0x100f44BaF6122E3eB6226FE19B65647AB14FfF05",
	"0x206dA00C34c867d23C8322701Be4ca745dd7dE75",
	"0x3e4D0e07Cab73719515dc32668c09dE90f802385",
	"0x71C9fd9D5552aC4F57549e2F33A61B9BcCD05225",
	"0xd3daE49e5AF9a948083c1E6D4d6Eeb620D28F736",
	"0x8330900856e61c4716A9dbfb9c8089443F303cd6",
	"0x82C64F3b7f871184E7c0E566EFB330dAE6b28a6A",
	"0xf521120ac1Fa07C75fB2Ab3688Ed0845aE16D453"
];

let whitelistAddresses2 = [];

// 3. Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const leafNodes2 = whitelistAddresses2.map(addr2 => keccak256(addr2));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });

// 4. Get root hash of the `merkleeTree` in hexadecimal format (0x)
// Print out the Entire Merkle Tree.
const rootHash = merkleTree.getRoot();
const rootHashHash = merkleTree.getHexRoot();

const rootHash2 = merkleTree2.getRoot();
const rootHashHash2 = merkleTree2.getHexRoot();


const ABI = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "ownerAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "ownerMint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "presale",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_presale_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setPresale_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_presaleCost",
				"type": "uint256"
			}
		],
		"name": "setPresaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_PUBLIC_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setPUBLIC_MAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_total_presale_limit",
				"type": "uint256"
			}
		],
		"name": "setTotal_presale_limit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_total_wl_limit",
				"type": "uint256"
			}
		],
		"name": "setTotal_wl_limit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_whitelistCost",
				"type": "uint256"
			}
		],
		"name": "setWhitelistCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "newWhitelistSigner",
				"type": "bytes32"
			}
		],
		"name": "setWhitelistSigner",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_wl_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setWl_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_presale_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_wl_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32[]",
				"name": "_proof",
				"type": "bytes32[]"
			},
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "whitelistMint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "presale_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "presale_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "presaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "presaleMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "PUBLIC_MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "publicSaleMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_presale_count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_presale_limit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_wl_count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_wl_limit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistSigner",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wl_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wl_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "wlMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0xcF205f7daceC11EaB1Cc2b0d6555C16edF6D3FF8";

let contract;
let max_per_wallet;

class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		statusErrorWL: false,
		statusLoadingWL: false,
		successWL: false,
		nftMintingAmount: 1,
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_public_mint_status: '',
		_presale_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,
		gallery: '',
		_navbarOpen: 0,
		_greeting: null,
		_provider: null,
		_signer: null,
		_contractZksync: null,
		sound: 0,
		_ser: 0,
		_paused: '',
		_wlEligible: '',
		_wlMinted: '',
		_presaleMinted: '',
		_maxBtn: 0,
		_max_per_wallet: '',
		_presaleCost: '',
		_total_wl_count: '',
		_total_presale_count: '',
		_presale_per_wallet: ''
	}

	/*async initializeProviderAndSigner() {
		const provider = new Provider('https://testnet.era.zksync.dev');
		// Note that we still need to get the Metamask signer
		const signer = (new Web3Provider(window.ethereum).getSigner)();
		contractZksync = new Contract(
			address,
			ABI,
			signer
		);
		this.setState({ _provider:provider, _signer:signer, _contractZksync:contractZksync });
		console.log("provider : " + provider);
		console.log("signer : " + signer);
		console.log("contractZksync : " + contractZksync);
	}/*

	async getGreeting() {
		const greeting = await this.state.contract.greet();
		this.setState({ greeting });
	}

	/*async getOverrides() {
		if (this.selectedToken.l1Address != ETH_L1_ADDRESS) {
		  const testnetPaymaster =
			await this.provider.getTestnetPaymasterAddress();
	  
		  const gasPrice = await this.provider.getGaspublicSaleCost();
	  
		  // estimate gasLimit via paymaster
		  const paramsForFeeEstimation = utils.getPaymasterParams(
			testnetPaymaster,
			{
			  type: "ApprovalBased",
			  minimalAllowance: ethers.BigNumber.from("1"),
			  token: this.selectedToken.l2Address,
			  innerInput: new Uint8Array(),
			}
		  );
	  
		  // estimate gasLimit via paymaster
		  const gasLimit = await this.contract.estimateGas.setGreeting(
			this.newGreeting,
			{
			  customData: {
				gasPerPubdata: utils.DEFAULT_GAS_PER_PUBDATA_LIMIT,
				paymasterParams: paramsForFeeEstimation,
			  },
			}
		  );
	  
		  const fee = gasPrice.mul(gasLimit.toString());
	  
		  const paymasterParams = utils.getPaymasterParams(testnetPaymaster, {
			type: "ApprovalBased",
			token: this.selectedToken.l2Address,
			minimalAllowance: fee,
			// empty bytes as testnet paymaster does not use innerInput
			innerInput: new Uint8Array(),
		  });
	  
		  return {
			maxFeePerGas: gasPrice,
			maxPriorityFeePerGas: ethers.BigNumber.from(0),
			gasLimit,
			customData: {
			  gasPerPubdata: utils.DEFAULT_GAS_PER_PUBDATA_LIMIT,
			  paymasterParams,
			},
		  };
		}
	  
		return {};
	  }*/

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}

	async componentDidMount() {

		try {

			if (localStorage?.getItem('src') == "1") {
				localStorage.setItem('src', "2");

				this.setState({ gallery: "1" });

			} else

				if (localStorage?.getItem('src') == "2") {
					localStorage.setItem('src', "3");

					this.setState({ gallery: "2" });

				} else

					if (localStorage?.getItem('src') == "3") {
						localStorage.setItem('src', "4");

						this.setState({ gallery: "3" });

					} else

						if (localStorage?.getItem('src') == "4") {
							localStorage.setItem('src', "1");

							this.setState({ gallery: "4" });

						} else {

							localStorage.setItem('src', "1");
							this.setState({ gallery: "1" });

						}


		} catch (err) {
			console.log(err);
		}

		if (localStorage?.getItem('isWalletConnected') === 'true') {
			try {
				const providerOptions = {
					walletconnect: {
						package: WalletConnectProvider, // required
						options: {
							infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
						}
					},
					/*coinbasewallet: {
						package: CoinbaseWalletSDK, // Required
						options: {
							appName: "Aterium Universe", // Required
							infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
							rpc: "", // Optional if `infuraId` is provided; otherwise it's required
							chainId: 1, // Optional. It defaults to 1 if not provided
							darkMode: true // Optional. Use dark theme, defaults to false
						}
					}*/
				};

				const web3Modal = new Web3Modal({
					network: "mainnet", // optional
					cacheProvider: true, // optional
					providerOptions // required
				});



				const provider = await web3Modal.connect();

				//  Enable session (triggers QR Code modal)
				await provider.enable();

				const web3 = new Web3(provider);
				console.log("provider : " + provider);
				// Subscribe to accounts change
				provider.on("accountsChanged", (accounts) => {
					console.log(accounts);
				});

				// Subscribe to chainId change
				provider.on("chainChanged", (chainId) => {
					console.log(chainId);
				});

				// Subscribe to provider connection
				provider.on("connect", (info) => {
					console.log(info);
					console.log("I'm LOGGED IN");
				});

				// Subscribe to provider disconnection
				provider.on("disconnect", (error) => {
					console.log(error);
				});

				// test if wallet is connected
				if (web3Modal.cachedProvider) {
					// connected now you can get accounts
					console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
					console.log("provider :" + provider);

					const accounts = await web3.eth.getAccounts();

					account = accounts[0];
					this.setState({ walletAddress: account });

					//const signer = (new Web3Provider(window.ethereum).getSigner)();

					contract = new web3.eth.Contract(ABI, address); console.log("contract :" + contract);

					if (provider) {


						//	(async () => {

						if (web3Modal.cachedProvider != "walletconnect" /*&& web3Modal.cachedProvider != "coinbasewallet"*/) {

							const chainId = 1;

							if (window.ethereum.networkVersion !== chainId) {
								try {
									await window.ethereum.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

													/*chainName: 'zkSync Era Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync Era Mainnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://mainnet.era.zksync.io'],*/

													//chainName: 'Metis Goerli',
													//chainId: web3.utils.toHex(chainId),
													//nativeCurrency: { name: 'Metis Goerli', decimals: 18, symbol: 'METIS' },
													//rpcUrls: ['https://goerli.gateway.metisdevops.link'],

													/*chainName: 'Metis Andromeda Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Metis Andromeda Mainnet', decimals: 18, symbol: 'METIS' },
													rpcUrls: ['https://andromeda.metis.io/?owner=1088'],*/

													/*chainName: 'Rinkeby Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://rinkeby.infura.io/v3/'],*/

													/*chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/

													chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://etherscan.io'],
												},
											],
										});
									}
								}
							}


							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}

								wlMinted = await contract.methods.wlMinted(account).call();
								this.setState({ _wlMinted: wlMinted });
								console.log("wlMinted:" + wlMinted);

								total_wl_count = await contract.methods.total_wl_count().call();
								this.setState({ _total_wl_count: total_wl_count });
								console.log("total_wl_count:" + total_wl_count);

								total_presale_count = await contract.methods.total_presale_count().call();
								this.setState({ _total_presale_count: total_presale_count });
								console.log("total_presale_count:" + total_presale_count);

								presaleMinted = await contract.methods.presaleMinted(account).call();
								this.setState({ _presaleMinted: presaleMinted });

								presaleCost = await contract.methods.presaleCost().call();
								this.setState({ _presaleCost: presaleCost });

								presale_per_wallet = await contract.methods.presale_per_wallet().call();
								this.setState({ _presale_per_wallet: presale_per_wallet });

								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								max_per_wallet = await contract.methods.max_per_wallet().call();
								this.setState({ _max_per_wallet: max_per_wallet });

								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });

								public_mint_status = await contract.methods.public_mint_status().call();

								if (public_mint_status) {
									this.setState({ _public_mint_status: true });
								} else {
									this.setState({ _public_mint_status: false });
								}
								console.log("public_mint_status :" + public_mint_status);


								presale_mint_status = await contract.methods.presale_mint_status().call();

								if (presale_mint_status) {

									this.setState({ _presale_mint_status: true });

								} else {

									this.setState({ _presale_mint_status: false });

								}

								console.log("presale_mint_status :" + presale_mint_status);

								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);
								//..........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 9000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 9000) {

										onlyLeft = 9000 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = 9000 - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;
										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///................................///



								//..........................................................................//

							}
							catch (err) {
								console.log("err: " + err);

							}

						} else if (web3Modal.cachedProvider == "walletconnect") {

							const chainId = 1;

							if (WalletConnectProvider.networkVersion !== chainId) {
								try {
									await WalletConnectProvider.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

													/*chainName: 'zkSync Era Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync Era Mainnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://mainnet.era.zksync.io'],*/

													//chainName: 'Metis Goerli',
													//chainId: web3.utils.toHex(chainId),
													//nativeCurrency: { name: 'Metis Goerli', decimals: 18, symbol: 'METIS' },
													//rpcUrls: ['https://goerli.gateway.metisdevops.link'],

													/*chainName: 'Metis Andromeda Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Metis Andromeda Mainnet', decimals: 18, symbol: 'METIS' },
													rpcUrls: ['https://andromeda.metis.io/?owner=1088'],*/

													/*chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/

													chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://etherscan.io'],
												},
											],
										});
									}
								}
							}


							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}

								wlMinted = await contract.methods.wlMinted(account).call();
								this.setState({ _wlMinted: wlMinted });
								console.log("wlMinted:" + wlMinted);

								total_wl_count = await contract.methods.total_wl_count().call();
								this.setState({ _total_wl_count: total_wl_count });
								console.log("total_wl_count:" + total_wl_count);

								total_presale_count = await contract.methods.total_presale_count().call();
								this.setState({ _total_presale_count: total_presale_count });
								console.log("total_presale_count:" + total_presale_count);

								presaleMinted = await contract.methods.presaleMinted(account).call();
								this.setState({ _presaleMinted: presaleMinted });

								presaleCost = await contract.methods.presaleCost().call();
								this.setState({ _presaleCost: presaleCost });

								presale_per_wallet = await contract.methods.presale_per_wallet().call();
								this.setState({ _presale_per_wallet: presale_per_wallet });

								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								max_per_wallet = await contract.methods.max_per_wallet().call();
								this.setState({ _max_per_wallet: max_per_wallet });

								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });

								public_mint_status = await contract.methods.public_mint_status().call();

								if (public_mint_status) {
									this.setState({ _public_mint_status: true });
								} else {
									this.setState({ _public_mint_status: false });
								}
								console.log("public_mint_status :" + public_mint_status);


								presale_mint_status = await contract.methods.presale_mint_status().call();

								if (presale_mint_status) {

									this.setState({ _presale_mint_status: true });

								} else {

									this.setState({ _presale_mint_status: false });

								}


								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);
								//..........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 41000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 41000) {

										onlyLeft = 41000 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = 9000 - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;
										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///................................///



								//..........................................................................//

							} catch (err) {
								console.log("err: " + err);

							}


						}
						//})();

						//.....................................................................//

						// Legacy providers may only have ethereum.sendAsync
						const chainId = await provider.request({
							method: 'eth_chainId'
						})

					} else {

						// if the provider is not detected, detectEthereumProvider resolves to null
						console.error('Please install a Valid Wallet');
						alert('A valid provider could not be found!');

					}

				}
			} catch (ex) {
				console.log(ex);
			}
		} else {

			console.log("NOT CONNECTED");

		}

	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 9000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;



			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {
			if (totalSupplyNFT < 9000) {

				onlyLeft = max_per_wallet - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });
				console.log("nftMintingAmount - hitting - :" + this.state.nftMintingAmount);

				this.setState({ totalValue: valueOfNFTs });
			}
		}

	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = max_per_wallet - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 9000) {

				if (presale_mint_status === true) {
					onlyLeft = presale_per_wallet - presaleMinted;
				} else


					if (public_mint_status == true) {
						onlyLeft = max_per_wallet - publicSale;
					}

				console.log(onlyLeft);


				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });
				console.log("nftMintingAmount - hitting + :" + this.state.nftMintingAmount);

				this.setState({ totalValue: valueOfNFTs });
			}
		}

	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		let owner = await contract.methods.owner().call();


		if (account != owner) {

			try {

				console.log("minAmount:" + mintAmount);
				console.log("valueOfNFTs:" + valueOfNFTs);

				this.setState({ statusError: false, statusLoading: true });
				await contract.methods.mint(this.state.nftMintingAmount).send({ gasLimit: 3000000, from: account, value: this.state.nftMintingAmount * this.state._cost });

				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.mintAmount);
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);


			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);

			}
		} else {
			try {

				console.log("minAmount:" + mintAmount);
				console.log("valueOfNFTs:" + valueOfNFTs);

				this.setState({ statusError: false, statusLoading: true });
				await contract.methods.mint(this.state.nftMintingAmount).send({ gasLimit: 3000000, from: account, value: this.state.nftMintingAmount * 0 });

				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.mintAmount);
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);


			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);

			}
		}
	}

	presale = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		let owner = await contract.methods.owner().call();


		if (account != owner) {
			try {


				this.setState({ statusError: false, statusLoading: true });
				await contract.methods.presale(this.state.nftMintingAmount).send({ gasLimit: 3000000, from: account, value: this.state.nftMintingAmount * this.state._presaleCost });

				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.mintAmount);
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);


			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);


			}
		} else {
			try {


				this.setState({ statusError: false, statusLoading: true });
				await contract.methods.presale(this.state.nftMintingAmount).send({ gasLimit: 3000000, from: account, value: this.state.nftMintingAmount * 0 });

				this.setState({ statusLoading: false, success: true });
				console.log("Mint Amount :" + this.state.mintAmount);
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);


			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);


			}
		}


	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });
			//const signer = (new Web3Provider(window.ethereum).getSigner)();

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);
			//const provider = new Provider('https://testnet.era.zksync.dev');
			// Note that we still need to get the Metamask signer
			/*const signer = (new Web3Provider(window.ethereum).getSigner)();
			contractZksync = new web3.eth.Contract(
				ABI,
				address,
				signer
			);
			this.setState({ _provider:provider, _signer:signer, _contractZksync:contractZksync });
			console.log("provider : " + provider);
			console.log("signer : " + signer);
			console.log("contractZksync : " + contractZksync);*/

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						const chainId = 1;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

												/*chainName: 'zkSync Era Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync Era Mainnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://mainnet.era.zksync.io'],*/

												//chainName: 'Metis Goerli',
												//chainId: web3.utils.toHex(chainId),
												//nativeCurrency: { name: 'Metis Goerli', decimals: 18, symbol: 'METIS' },
												//rpcUrls: ['https://goerli.gateway.metisdevops.link'],

												/*chainName: 'Metis Andromeda Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Metis Andromeda Mainnet', decimals: 18, symbol: 'METIS' },
												rpcUrls: ['https://andromeda.metis.io/?owner=1088'],*/

												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],
											},
										],
									});
								}
							}
						}





						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							wlMinted = await contract.methods.wlMinted(account).call();
							this.setState({ _wlMinted: wlMinted });
							console.log("wlMinted:" + wlMinted);

							total_wl_count = await contract.methods.total_wl_count().call();
							this.setState({ _total_wl_count: total_wl_count });
							console.log("total_wl_count:" + total_wl_count);

							total_presale_count = await contract.methods.total_presale_count().call();
							this.setState({ _total_presale_count: total_presale_count });
							console.log("total_presale_count:" + total_presale_count);

							presaleMinted = await contract.methods.presaleMinted(account).call();
							this.setState({ _presaleMinted: presaleMinted });

							presaleCost = await contract.methods.presaleCost().call();
							this.setState({ _presaleCost: presaleCost });

							presale_per_wallet = await contract.methods.presale_per_wallet().call();
							this.setState({ _presale_per_wallet: presale_per_wallet });

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							max_per_wallet = await contract.methods.max_per_wallet().call();
							this.setState({ _max_per_wallet: max_per_wallet });

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							public_mint_status = await contract.methods.public_mint_status().call();

							if (public_mint_status) {
								this.setState({ _public_mint_status: true });
							} else {
								this.setState({ _public_mint_status: false });
							}
							console.log("public_mint_status :" + public_mint_status);


							presale_mint_status = await contract.methods.presale_mint_status().call();

							if (presale_mint_status) {

								this.setState({ _presale_mint_status: true });

							} else {

								this.setState({ _presale_mint_status: false });

							}


							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);
							//..........................................................................//
							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = max_per_wallet - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 9000) {

									onlyLeft = max_per_wallet - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = max_per_wallet - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 1;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

												/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

												/*chainName: 'zkSync Era Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync Era Mainnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://mainnet.era.zksync.io'],*/

												//chainName: 'Metis Goerli',
												//chainId: web3.utils.toHex(chainId),
												//nativeCurrency: { name: 'Metis Goerli', decimals: 18, symbol: 'METIS' },
												//rpcUrls: ['https://goerli.gateway.metisdevops.link'],

												/*chainName: 'Metis Andromeda Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Metis Andromeda Mainnet', decimals: 18, symbol: 'METIS' },
												rpcUrls: ['https://andromeda.metis.io/?owner=1088'],*/

												chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],
											},
										],
									});
								}
							}
						}




						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							wlMinted = await contract.methods.wlMinted(account).call();
							this.setState({ _wlMinted: wlMinted });
							console.log("wlMinted:" + wlMinted);

							total_wl_count = await contract.methods.total_wl_count().call();
							this.setState({ _total_wl_count: total_wl_count });
							console.log("total_wl_count:" + total_wl_count);

							total_presale_count = await contract.methods.total_presale_count().call();
							this.setState({ _total_presale_count: total_presale_count });
							console.log("total_presale_count:" + total_presale_count);

							presaleMinted = await contract.methods.presaleMinted(account).call();
							this.setState({ _presaleMinted: presaleMinted });

							presaleCost = await contract.methods.presaleCost().call();
							this.setState({ _presaleCost: presaleCost });

							presale_per_wallet = await contract.methods.presale_per_wallet().call();
							this.setState({ _presale_per_wallet: presale_per_wallet });

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							max_per_wallet = await contract.methods.max_per_wallet().call();
							this.setState({ _max_per_wallet: max_per_wallet });

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							public_mint_status = await contract.methods.public_mint_status().call();

							if (public_mint_status) {
								this.setState({ _public_mint_status: true });
							} else {
								this.setState({ _public_mint_status: false });
							}
							console.log("public_mint_status :" + public_mint_status);


							presale_mint_status = await contract.methods.presale_mint_status().call();

							if (presale_mint_status) {

								this.setState({ _presale_mint_status: true });

							} else {

								this.setState({ _presale_mint_status: false });

							}



							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 9000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 9000) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = max_per_wallet - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload(true);

		try {
			localStorage.setItem('isWalletConnected', false)
		} catch (ex) {
			console.log(ex)
		}

	}


	whitelistMint = async event => {
		event.preventDefault();


		//	console.log('Whitelist Merkle Tree\n', merkleTree.toString());
		console.log("Root Hash: ", rootHash);
		console.log("Root HashHash: ", rootHashHash);

		// ***** ***** ***** ***** ***** ***** ***** ***** // 

		// CLIENT-SIDE: Use `msg.sender` address to query and API that returns the merkle proof
		// required to derive the root hash of the Merkle Tree

		// ✅ Positive verification of address
		//const claimingAddress = leafNodes[0];
		//console.log("Claiming Address:"+ claimingAddress);
		// ❌ Change this address to get a `false` verification
		const claimingAddress = keccak256(account);

		// `getHexProof` returns the neighbour leaf and all parent nodes hashes that will
		// be required to derive the Merkle Trees root hash.
		const hexProof = merkleTree.getHexProof(claimingAddress);
		console.log("HexProof:" + hexProof);

		// ✅ - ❌: Verify is claiming address is in the merkle tree or not.
		// This would be implemented in your Solidity Smart Contract
		console.log("Final result: " + merkleTree.verify(hexProof, claimingAddress, rootHash));
		FinalResult = merkleTree.verify(hexProof, claimingAddress, rootHash);
		this.setState({ _FinalResult: FinalResult });

		let wl_value = this.state._wlMintAmount * 1 * wlCost;
		let total_wl_mintings = publicSale * 1 + this.state._wlMintAmount * 1;
		console.log("total_wl_mintings:" + total_wl_mintings);
		console.log("publicSale:" + publicSale);
		console.log("wlMintAmount:" + this.state._wlMintAmount);

		if (FinalResult) {
			try {
				this.setState({ statusErrorWL: false, statusLoadingWL: true });
				await contract.methods.whitelistMint(hexProof, 1).send({ gasLimit: 385000, from: account, value: 20000000000000000 });

				this.setState({ statusLoadingWL: false, successWL: true });
				console.log("Mint Amount :" + this.state.mintAmount);
				await new Promise(resolve => setTimeout(resolve, 5000));
				window.location.reload(true);
			} catch (err) {
				this.setState({ errorMassageWL: "ERROR : " + err.message, statusLoadingWL: false, successWL: false, statusErrorWL: true });
				console.log(err);
			}
		} else {
			wlMsg = "Not Eligible"
			this.setState({ _wlMsg: wlMsg });
		}


	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	maxBtn = async event => {
		event.preventDefault();

		this.setState({ _maxBtn: 1 });
		if ((9000 - totalSupplyNFT) > 100) {
			mintAmount = 100;
		} else {
			mintAmount = 9000 - (totalSupplyNFT * 1);
		}

		this.setState({ nftMintingAmount: mintAmount });

	}

	render() {

		return (

			<div class="allWrap">
				<div class="light">
					<div class="cont">

						<div class="headers">

							<div class="h1">

								<div class="logoDiv">
									<img class="logo" src={logo} />
								</div>

								<div class="connect">

									<div class="icons">
										<img src={discord} />
										<img src={twitter} />
										<img onClick={os} src={opensea} />
									</div>

									{this.state.walletAddress === '' ?
										(<form class="connect2">
											<button onClick={this.walletConnect} class="connectBtn" >Connect Wallet</button>
										</form>) : (<form class="connect2" onClick={this.walletDisconnect}><button class="connectBtn" >
											{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}
								</div>

							</div>

						</div>



						<Element name="mint">
							<div class="boxWrap2Mint">
								<div class="mint2" >
									<div class="rightMintSection">
										<img class="nftMint" src={nft} />

										<div class="flexDiv">
											<div class="flexDiv2">
												<div class="mintCon1">Total Minted</div>
												<div class="dashDiv"><img class="dash" src={dash} /></div>
												<div class="mintCon2">{this.state.totalSupply}/10000</div>
											</div>
										</div>

										<div class="flexDiv">
											<div class="flexDiv2">

												<div class="mintCon1">Price</div>
												<div class="dashDiv"><img class="dash" src={dash} /></div>
												{this.state._public_mint_status === true ?
													(<div class="mintCon2">{this.state._public_sale_cost / 1000000000000000000} ETH</div>) : null}

												{this.state._presale_mint_status === true ?
													(<div class="mintCon2">{this.state._presaleCost / 1000000000000000000} ETH</div>) : null}
											</div>
										</div>

										<div class="flexDiv">
											<div class="flexDiv2">

												<div class="mintCon1">Cost</div>
												<div class="dashDiv"><img class="dash" src={dash} /></div>
												{this.state._public_mint_status === true ?
													(<div class="mintCon2">{this.state._public_sale_cost * mintAmount / 1000000000000000000} ETH</div>) : null}

												{this.state._presale_mint_status === true ?
													(<div class="mintCon2">{this.state._presaleCost * mintAmount / 1000000000000000000} ETH</div>) : null}
											</div>
										</div>
										<div class="mintBar">
											<div class="mintCon3" onClick={this.onSubmitMinus}><img src={minus} /></div>
											<div class="nftMintingAmount">{this.state.nftMintingAmount}</div>
											<div class="mintCon3" onClick={this.onSubmitPlus}><img src={plus} /></div>

										</div>
									</div>
									<div class="leftMintSection">

										<div class="flexDivMain">
											<div class="flexDiv3">

												<div class="mintCon1-N">Whitelist</div>
												<div class="dashDiv"><img class="dash" src={dash} /></div>
												<div class="mintCon1-N">{this.state._total_wl_count}/200</div>
											</div>

											<div class="flexDiv3-2">

												<div class="mintCon1-N">Presale</div>
												<div class="dashDiv"><img class="dash" src={dash} /></div>
												<div class="mintCon1-N">{this.state._total_presale_count}/200</div>
											</div>
										</div>
										{this.state._public_mint_status === true ?
											(<div class="mintT">PUBLIC MINT <span class="hl">LIVE</span></div>) : null}

										{this.state._presale_mint_status === true ?
											(<div class="mintT">PRESALE MINT <span class="hl">LIVE</span></div>) : null}

										<div class="mintCon2--1">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Obcaecati unde at odit laudantium.</div>

										<p class="mintCon2--2">MAX {this.state._max_per_wallet} NFTS PER WALLET</p>
										<p class="mintCon2--2">PRICE {this.state._public_sale_cost / 1000000000000000000} ETH + GAS</p>

										{this.state.walletAddress === '' ?

											(<div class="mintbuttondiv">
												<button onClick={this.walletConnect} class="mint">Connect Mint</button>
											</div>) :
											(<div class="mintbuttondiv">
												{this.state._presale_mint_status === true ? (
													<div>
														<form onSubmit={this.presale}>
															<button class="mint" type="submit">
																PRESALE MINT</button>
														</form>
													</div>
												) : null} {this.state._public_mint_status === true ? (
													<form onSubmit={this.onSubmit2}>
														<button class="mint" type="submit">
															PUBLIC SALE MINT</button>
													</form>
												) : null}
											</div>)}
										<div>

											{this.state.statusError ? (
												<div class="errorMessage">
													<div >Sorry, something went wrong please try again later</div>
												</div>)
												: null}

											{this.state.statusLoading ? (
												<div class="loadingContainer">
													<div>
														<div class="loadingText">Minting your NFT</div>
													</div>
												</div>)
												: null}

											{this.state.success ? (

												<div class="successfully">
													<div>Successful NFT Minting!</div>
												</div>)
												: null}

										</div>
										{this.state.walletAddress === '' ?

											(<div class="mintbuttondiv">
												<button onClick={this.walletConnect} class="mint">Connect Whitelist</button>
											</div>) :
											(<form onSubmit={this.whitelistMint}>
												<button class="mint" type="submit">
													{this.state._wlMsg}</button>
											</form>)}


										<div>

											{this.state.statusErrorWL ? (
												<div class="errorMessage">
													<div >Sorry, something went wrong please try again later</div>
												</div>)
												: null}

											{this.state.statusLoadingWL ? (
												<div class="loadingContainer">
													<div>
														<div class="loadingText">Minting your NFT</div>
													</div>
												</div>)
												: null}

											{this.state.successWL ? (

												<div class="successfully">
													<div>Successful NFT Minting!</div>
												</div>)
												: null}

										</div>


									</div>
								</div>
								<div class="boxWrap2Footer">
									<div class="copyright">COPYRIGHT 2023. ALL RIGHTS RECEIVED</div>
								</div>
							</div>
						</Element>
					</div>
				</div>

			</div>)
	}
}

export default App;
