import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {

    render() {
        return (

            <div class="boxWrap2Footer">
                <div class="copyright">COPYRIGHT 2023. I AM SAM NFT</div>
            </div>
        )
    }
}

export default Footer;

